const clients = [
    {
        id: 3,
        system: 'FAN',
        module: 'ZNAP_SECURITY',
        modulePath: 'security',
        language: 'pt',
        port: 8081,
        theme: 'fan.js',
        env: 'prod'
    }
]

const getClientConfigs = (clientId) => clients.find(client => client.id === clientId)
const configs = getClientConfigs(3)

export default {
    ...configs
}
