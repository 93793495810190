import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import './plugins/axios'
import './plugins/vee'
import './plugins/vuetoastification'
// import configs from '@/configs'

import { getPermissions, errorFunction, filters } from '@/utils'
Vue.filter('dateFilter', filters.dateFilter)
Vue.filter('floatFilter', filters.floatFilter)
Vue.filter('cpfCnpjFilter', filters.cpfCnpjFilter)

Vue.prototype.$fnError = errorFunction

Vue.config.productionTip = false

Vue.prototype.$getPermissions = getPermissions

Vue.prototype.$ipUser = `https://fan.api.user.znaptech.com/`
Vue.prototype.$ipClient = `https://fan.api.client.znaptech.com/`
Vue.prototype.$ipSecurity = `https://fan.api.security.znaptech.com/`
Vue.prototype.$ipEvent = `https://fan.api.event.znaptech.com/`
Vue.prototype.$ipAccount = `https://fan.api.account.znaptech.com/`
Vue.prototype.$ipCustomer = `https://fan.api.customer.znaptech.com/`
Vue.prototype.$ipOrganization = `https://fan.api.organization.znaptech.com/`
Vue.prototype.$ipUnit = `https://fan.api.unit.znaptech.com/`
Vue.prototype.$ipMessage = `https://fan.api.message.znaptech.com/`
Vue.prototype.$ipIntegration = `https://fan.api.integration.znaptech.com/`
Vue.prototype.$vuetify = vuetify

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
